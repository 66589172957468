import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import devices from 'theme/devices';

const query = graphql`
  {
    allFile(filter: {name: {regex: "/logo/"}}) {
        nodes {
          publicURL
        }
    }
  }
`;

interface Query {
  allFile: {
    nodes: { publicURL: string }[];
  }
}

interface LogoProps {
  version: string;
}

function Logo({ version }: LogoProps) {
  const { allFile: { nodes } } = useStaticQuery<Query>(query);
  const currentLogo = nodes.find((node) => node.publicURL.includes(version));

  return (
    <Image src={currentLogo?.publicURL || ''} alt="Black is Black logo" />
  );
}

const Image = styled.img`
  display: block;
  height: 38px;
  padding: 0;
  margin: 0;

  ${devices.tablet} {
    height: 44px;
  }
`;

export default Logo;
