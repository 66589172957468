import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled, { css } from 'styled-components';
import devices from 'theme/devices';

const List = styled.ul`
  position: fixed;
  z-index: 140;
  bottom: 15px;
  right: 15px;
  transition: transform 0.5s 0.4s ease-in-out;
  list-style-type: none;
  margin: 0;
  padding: 0;

  ${devices.tablet} {
    right: 25px;
    bottom: 25px;
  }

  ${devices.tabletL} {
    transform: translateX(0);
    right: 30px;
    bottom: 30px;
  }

  ${devices.desktop} {
    right: 90px;
    bottom: 90px;
  }
`;

const ListItem = styled.li`
  display: block;
  padding: 0;
  margin: 0;
`;

const Link = styled.a`
  display: block;
  padding: 10px;
  margin: 0;
`;

const Icon = styled.img<{behance: boolean}>`
  display: block;
  height: 16px;
  padding: 0;
  margin: 0 auto;

  ${devices.tablet} {
    height: 18px;
  }

  ${devices.tabletL} {
    height: 20px;
  }

  ${({ behance }) => behance && css`
    height: 21px;

    ${devices.tablet} {
     height: 23px;
    }

    ${devices.tabletL} {
      height: 25px;
    }
  `}
`;

const query = graphql`
  {
   allFile(filter: { absolutePath: { regex: "/social-icon/" } }) {
      nodes {
         name
         publicURL
      }
   }
   datoCmsSocial {
      behance
      facebook
      instagram
      linkedin
   }
  }
`;

interface SocialMediaProps {
   white: boolean;
   className?: string;
}

interface SocialMediaItem {
   name: string;
   publicURL: string;
}

interface Query {
   datoCmsSocial: {
      behance: string;
      facebook: string;
      instagram: string;
      linkedin: string;
   };
   allFile: {
      nodes: SocialMediaItem[];
   };
}

function SocialMedia({ className, white }:SocialMediaProps) {
  const { allFile: { nodes }, datoCmsSocial } = useStaticQuery<Query>(query);

  const socialMedia = nodes.filter((item) => item.name.includes(white ? 'white' : 'black'));

  const getSocialIcon = (name: string) => socialMedia.find((item) => item.name.startsWith(name))
    ?.publicURL;

  return (
    <List className={className}>
      <ListItem>
        <Link href={datoCmsSocial.facebook} target="_blank">
          <Icon src={getSocialIcon('fb')} alt="Facebook" behance={false} />
        </Link>
      </ListItem>
      <ListItem>
        <Link href={datoCmsSocial.instagram} target="_blank">
          <Icon src={getSocialIcon('ig')} alt="Instagram" behance={false} />
        </Link>
      </ListItem>
      <ListItem>
        <Link href={datoCmsSocial.linkedin} target="_blank">
          <Icon src={getSocialIcon('in')} alt="LinkedIn" behance={false} />
        </Link>
      </ListItem>
      <ListItem>
        <Link href={datoCmsSocial.behance} target="_blank">
          <Icon src={getSocialIcon('be')} alt="Behance" behance />
        </Link>
      </ListItem>
    </List>
  );
}

SocialMedia.defaultProps = {
  className: '',
};

export default SocialMedia;
