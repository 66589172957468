const devices = {
  mobile: '@media (min-width: 560px)',
  mobileL: '@media (min-width: 640px)',
  tablet: '@media (min-width: 768px)',
  tabletL: '@media (min-width: 1024px)',
  tabletXL: '@media (min-width: 1180px)',
  desktop: '@media (min-width: 1366px)',
  desktopHD: '@media (min-width: 1600px)',
  desktopAlmostFHD: '@media (min-width: 1700px)',
};

export default devices;
